import type { ActionableId, ContentId, ContentIxStatusActionable, ContentIxStatusMiab, ContentIxStatusSummary, LgxpId, SummaryId } from "@newgenerated/shared/schema";
import type { Store } from "@/common/storeUtils";
import { getIxStatus } from "@newgenerated/shared/stubs";

type ContentIxSummaryStatuses = Map<SummaryId["id"], ContentIxStatusSummary>;
type ContentIxMiabStatuses = Map<SummaryId["id"], ContentIxStatusMiab>;
type ContentIxActionableStatuses = Map<ActionableId["id"], ContentIxStatusActionable>;
type ContentIxSketchnoteStatuses = Map<LgxpId["id"], null>; // TODO: Not yet implemented

export type ContentIxStatuses = {
  SUMMARY: ContentIxSummaryStatuses;
  MIAB: ContentIxMiabStatuses;
  ACTIONABLE: ContentIxActionableStatuses;
  SKETCHNOTE: ContentIxSketchnoteStatuses;
};

export function getInitialContentIxStatuses(): ContentIxStatuses {
  return {
    SUMMARY: new Map(),
    MIAB: new Map(),
    ACTIONABLE: new Map(),
    SKETCHNOTE: new Map(),
  };
}

/**
 * Fetches Content Ix status of `ids` and updates the store accordingly.
 * In case of an error the store stays as is.
 */
export async function updateContentIxStatuses(store: Store<ContentIxStatuses>, ids: ContentId[]): Promise<void> {
  const response = await getIxStatus({ body: { items: ids } });
  if (response.kind === "error") {
    return;
  }

  const newContentIxStatuses = response.data.items;

  const summaryStatuses: ContentIxSummaryStatuses = new Map([...store.sub("SUMMARY").get()]);
  const miabStatuses: ContentIxMiabStatuses = new Map([...store.sub("MIAB").get()]);
  const actionableStatuses: ContentIxActionableStatuses = new Map([...store.sub("ACTIONABLE").get()]);

  ids.forEach((contentId) => {
    if (contentId.contentType === "SUMMARY") {
      const summaryIxStatus = newContentIxStatuses.find((entry): entry is ContentIxStatusSummary => {
        return entry.contentId.id === contentId.id && entry.contentId.contentType === contentId.contentType && entry.contentType === "SUMMARY";
      });

      if (summaryIxStatus === undefined) {
        summaryStatuses.delete(contentId.id);
      } else {
        summaryStatuses.set(contentId.id, summaryIxStatus);
      }

      const miabIxStatus = newContentIxStatuses.find((entry): entry is ContentIxStatusMiab => {
        return entry.contentId.id === contentId.id && entry.contentId.contentType === contentId.contentType && entry.contentType === "MIAB";
      });

      if (miabIxStatus === undefined) {
        miabStatuses.delete(contentId.id);
      } else {
        miabStatuses.set(contentId.id, miabIxStatus);
      }
    }

    store.sub("SUMMARY").set(summaryStatuses);
    store.sub("MIAB").set(miabStatuses);

    if (contentId.contentType === "ACTIONABLE") {
      const actionableIxStatus = newContentIxStatuses.find((entry): entry is ContentIxStatusActionable => {
        return entry.contentId.id === contentId.id && entry.contentId.contentType === contentId.contentType;
      });

      if (actionableIxStatus === undefined) {
        actionableStatuses.delete(contentId.id);
      } else {
        actionableStatuses.set(contentId.id, actionableIxStatus);
      }
    }

    store.sub("ACTIONABLE").set(actionableStatuses);

    if (contentId.contentType === "SKETCHNOTE") {
      // TODO: Not yet implemented
    }
  });
}
