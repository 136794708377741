import type { ContentMetadata } from "@newgenerated/shared/schema";
import { useGaContext } from "@utils/vue-migration/common/gaContext/gaContext";

export function getMiabBaseLink(miab: ContentMetadata): URL {
  const { currentLanguage, asp } = useGaContext();
  const url = new URL(`/${currentLanguage()}${miab.common.link}`, window.location.origin);
  if (asp !== null) {
    url.searchParams.set("u", asp.username);
  }
  return url;
}
