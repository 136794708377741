import type { ContentCardDemoProps, ContentIxStatus, ContentMetadata } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaBadge } from "@/components/general/GaBadge";
import { GaButton } from "@/components/general/GaButton";
import { ContentCard, getInitialSuspendTranslationFns, type TranslationFnAsync, type TranslationFnsAsync } from "@/components/cards/ContentCard";
import { defineComponent, onMounted, type PropType } from "vue";
import { createStore, type Store } from "@/common/storeUtils";
import { ensureNonNull } from "@utils/assertion";
import { type ContentIxStatuses, getInitialContentIxStatuses, updateContentIxStatuses } from "@/common/contentUtils";

type DemoState = {
  columns: {
    horizontal: number;
    vertical: number;
  };
  translationFns: TranslationFnsAsync;
  contentIxStatuses: ContentIxStatuses;
};

function Demo(props: { store: Store<DemoState>; contentCardDemoProps: ContentCardDemoProps; title: string; layout: "horizontal" | "vertical" }): GaVueComponent {
  const { summaries, miabs, actionables, sketchnotes } = props.contentCardDemoProps;
  const numberOfElements = summaries.length + actionables.length + sketchnotes.length + miabs.length;

  const additionalParams = new URLSearchParams();
  additionalParams.append("st", "ACTIONABLE");
  additionalParams.append("si", "1");

  const contentIxStatuses = props.store.sub("contentIxStatuses");

  function helper(content: ContentMetadata | undefined, cb: (content: ContentMetadata, t: Store<TranslationFnAsync>, ixStatus?: ContentIxStatus) => GaVueComponent): GaVueComponent {
    const c = ensureNonNull(content);
    const t = props.store.sub("translationFns").sub(c.common.language);
    const ixStatus = contentIxStatuses.sub(c.contentId.contentType).get().get(c.contentId.id);

    return cb(c, t, ixStatus ?? undefined);
  }

  const layoutStore = props.store.sub("columns").sub(props.layout);

  return (
    <div class="mb-5">
      <h2>{props.title}</h2>
      <div data-card-demo="">
        <div class="demo-bar mb-3">
          <span>
            <GaBadge variant="muted">VUE</GaBadge>
          </span>
          <div class="demo-bar-controls">
            <GaButton size={"sm"} onClick={() => layoutStore.set(Math.min(layoutStore.get() + 1, numberOfElements))}>
              +
            </GaButton>
            <GaButton size={"sm"} onClick={() => layoutStore.set(Math.max(layoutStore.get() - 1, 1))}>
              -
            </GaButton>
          </div>
        </div>
        <div class="demo-flexible-columns" style={`--row-number: ${layoutStore.get()}`}>
          {helper(props.contentCardDemoProps.summaries[0], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.actionables[0], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[0], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.miabs[0], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.summaries[1], (content, t, ixStatus) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.actionables[1], (content, t, ixStatus) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[1], (content, t, ixStatus) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.miabs[1], (content, t, ixStatus) => (
            <ContentCard content={content} additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.summaries[2], (content, t, ixStatus) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.actionables[2], (content, t, ixStatus) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[2], (content, t, ixStatus) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.miabs[2], (content, t, ixStatus) => (
            <ContentCard content={content} link="/free-abstracts/123?u=gA_Demo" additionalParams={additionalParams} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.summaries[3], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.actionables[3], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[3], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.miabs[3], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.summaries[4], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.actionables[4], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.sketchnotes[4], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
          {helper(props.contentCardDemoProps.miabs[4], (content, t, ixStatus) => (
            <ContentCard content={content} layout={props.layout} translationFn={t} ixStatus={ixStatus} />
          ))}
        </div>
      </div>
    </div>
  );
}

export const ContentCardDemo = defineComponent({
  props: {
    contentCardDemoProps: {
      type: Object as PropType<ContentCardDemoProps>,
      required: true,
    },
  },
  setup(props) {
    const store = createStore<DemoState>({
      columns: {
        horizontal: 2,
        vertical: 3,
      },
      translationFns: getInitialSuspendTranslationFns(),
      contentIxStatuses: getInitialContentIxStatuses(),
    });

    onMounted(async () => {
      const summaryIds = props.contentCardDemoProps.summaries.map((entry) => entry.contentId);
      const miabIds = props.contentCardDemoProps.miabs.map((entry) => entry.contentId);
      const actionableIds = props.contentCardDemoProps.actionables.map((entry) => entry.contentId);
      const sketchnoteIds = props.contentCardDemoProps.sketchnotes.map((entry) => entry.contentId);
      await updateContentIxStatuses(store.sub("contentIxStatuses"), [...summaryIds, ...miabIds, ...actionableIds, ...sketchnoteIds]);
    });

    return () => (
      <div>
        <Demo store={store} contentCardDemoProps={props.contentCardDemoProps} title={"Vertical"} layout={"vertical"} />
        <Demo store={store} contentCardDemoProps={props.contentCardDemoProps} title={"Horizontal"} layout={"horizontal"} />
      </div>
    );
  },
});
